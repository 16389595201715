export default {
  primary: {
    backgroundColor: 'primary',
    color: 'light',
    border: 'solid 2px',
    borderColor: 'primary',
    ':hover': {
      backgroundColor: 'transparent'
    }
  },
  secondary: {
    backgroundColor: 'transparent',
    color: 'primary',
    transition: 'all ease-in-out 0.7s',
    padding: '1.25rem 4rem',
    fontFamily: 'heading',
    fontWeight: 'normal',
    // borderTop: 'solid 1px white',
    // borderLeft: 'solid 1px white',
    // borderRight: 'solid 1px white',
    // borderBottom: 'solid 1px black',
    border: '1px solid',
    borderColor: 'primary',
    ':hover': {
      // backgroundColor: 'transparent',
      // backgroundPosition: '-98% 0%',

      color: 'light',
      // border: 'solid 1px',
      // borderColor: 'primary',
      backgroundColor: 'primary'
    }
  }
}
